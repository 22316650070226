import React, { useState, useEffect } from "react";
import * as Constants from "../components/constants";
import "./CertificateOfAnalysis.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./certificateOfAnalysisValidation";
import Input from "../components/forms/Input";
import Select from "../components/forms/Select";
import { CircleSpinnerOverlay } from "react-spinner-overlay";

export default function CertificateOfAnalysis(props) {
    const [apiBaseUrl, setApiBaseUrl] = useState(null);
    const [apiKey, setApiKey] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [message, setMessage] = useState("");
    const [downloadMessage, setDownloadMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, reset, setValue, formState: { errors, ...formState } } = useForm({ resolver: yupResolver(validationSchema) });
    const inputProps = { register, errors };
    const { isDirty, isSubmitting } = formState;

    useEffect(() => {
        document.title = "Bio X Cell Certificate of Analysis Request";

        let apiBaseUrl = "";
        let apiKey = "";

        (async () => {
            await fetch("/api/settings", {
                method: "GET",
                headers: { Accept: "application/json", "Content-Type": "application/json" },
            })
                .then(response => response.text())
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.errors && response.errors.length) {
                        console.error(response);
                        return;
                    }

                    apiBaseUrl = response.apiBaseUrl;
                    setApiBaseUrl(apiBaseUrl);
                    apiKey = response.apiKey;
                    setApiKey(apiKey);

                    setValue("catalogCode", props.catalogCode);
                    setValue("lotNumber", props.lotNumber);
                    setValue("vesselSizeMg", props.vesselSizeMg);
                    setValue("vesselCount", props.vesselCount);
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    }, []);

    const sanitizeRequest = (request) => {
        request.vesselSizeMg = +request.vesselSizeMg;
        request.vesselCount = +request.vesselCount;

        //console.log(JSON.stringify(request, null, 2));

        return request;
    };

    const handleOnSubmit = (request) => {
        setIsLoading(true);

        let errorMessage = "";
        setMessage("");
        setDownloadMessage("");

        const sizes = ["1", "5", "25", "50"];
        if (request.vesselSizeMg && request.vesselSizeMg <= 50 && !sizes.includes(request.vesselSizeMg)) errorMessage = "Invalid vial size.";
        if (!request.lotNumber && (request.vesselSizeMg || request.vesselCount)) errorMessage = "Lot number required when vial size or count is specified.";
        if (request.lotNumber && !request.vesselSizeMg) errorMessage = "Vial size required when lot number is specified.";
        if (request.lotNumber && request.vesselCount && !request.vesselSizeMg) errorMessage = "Vial size required when vial count is specified.";

        setMessage(errorMessage);
        if (errorMessage) return;

        setSubmitDisabled(true);

        request = sanitizeRequest(request);

        let model = {};

        (async () => {
            let modelFailure = false;

            const lot = request.lotNumber ? request.lotNumber : "0";
            const size = request.vesselSizeMg ? request.vesselSizeMg : 0;
            const count = request.vesselCount ? request.vesselCount : 0;

            await fetch(`${apiBaseUrl}/products/certificate-of-analysis/model/${request.catalogCode}/${lot}/${size}/${count}`, {
                method: "GET",
                headers: { Accept: "application/json", "Content-Type": "application/json", "x-api-key": apiKey },
            })
                .then(response => response.text())
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.errors && response.errors.length) {
                        console.error(response);
                        modelFailure = true;
                        setMessage(response.errors[0]);
                        setSubmitDisabled(false);
                        setIsLoading(false);
                        return;
                    }

                    model = response;
                })
                .catch(error => {
                    console.error(error);
                    setIsLoading(false);
                });

            if (!modelFailure) {
                const params = new URLSearchParams(window.location.search);
                const behavior = params.get("behavior");
                if (behavior && behavior === "open") {
                    //console.log(`${apiBaseUrl}/products/certificate-of-analysis/${request.catalogCode}/${request.lotNumber ? request.lotNumber : "0"}/${request.vesselSizeMg}/${request.vesselCount}?apikey=9C57A3A337A040C89439F3CFA622F6D5`);
                    window.open(`${apiBaseUrl}/products/certificate-of-analysis/${request.catalogCode}/${request.lotNumber ? request.lotNumber : "0"}/${request.vesselSizeMg}/${request.vesselCount}?apikey=9C57A3A337A040C89439F3CFA622F6D5`, "_blank");
                    setSubmitDisabled(false);
                } else {
                    await fetch(`${apiBaseUrl}/products/certificate-of-analysis`, {
                        method: "POST",
                        headers: { Accept: "application/json", "Content-Type": "application/json", "x-api-key": apiKey },
                        body: JSON.stringify(request),
                    })
                        .then(response => {
                            if (response.ok) {
                                return response.blob().then(blob => {
                                    const url = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
                                    const link = document.createElement("a");
                                    link.href = url;
                                    const pdfFileName = `${model.shortTitle}.pdf`;
                                    link.setAttribute("download", pdfFileName);
                                    //document.body.appendChild(link);
                                    link.download = pdfFileName;
                                    link.click();
                                    link.parentNode.removeChild(link);

                                    setSubmitDisabled(false);
                                    setIsLoading(false);
                                })
                            } else {
                                return response.text().then(text => {
                                    const json = (text.length ? JSON.parse(text) : {});
                                    if (json.errors && json.errors.length) {
                                        setDownloadMessage(json.errors[0]);
                                    }

                                    console.error(json);
                                    setSubmitDisabled(false);
                                    setIsLoading(false);
                                })
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            setSubmitDisabled(false);
                            setIsLoading(false);
                        });
                }
            }
        })();
    };

    return (
        <>

            <div className="container">

                <CircleSpinnerOverlay loading={isLoading} color={Constants.LoadingSpinnerColor} overlayColor={Constants.LoadingOverlayColor} />

                <h1>Certificate of Analysis Request</h1>

                <div>

                    <p>
                        Please complete the following form to request a certificate of analysis.  Instructions:
                    </p>

                    <ul>
                        <li>If you received a single vial for a given lot number in your order, leave vial count blank.</li>
                        <li>If you received multiple vials for a given lot number in your order, select the per-vial mg amount for the vial size, and the number of vials for vial count.
                            For example, if you ordered 100 mg and received 4 vials, select vial size 25 mg and vial count 4.</li>
                        <li>For a Technical Data Sheet (TDS), leave lot number, vial size, and vial count blank.</li>
                    </ul>

                    <div className={`alert alert-danger${message || downloadMessage ? "" : " d-none"}`}>
                        {message}
                        {downloadMessage ?
                            (<>
                                <br />
                                {downloadMessage}
                            </>) : null}
                    </div>

                    <form onSubmit={handleSubmit(handleOnSubmit)}>

                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <Input id="catalogCode"
                                    label="Catalog Number"
                                    {...inputProps} />
                            </div>
                            <div className="col-md-3 mb-3">
                                <Input id="lotNumber"
                                    autoFocus={(props.catalogCode && props.lotNumber === null && props.vesselSizeMg === null && props.vesselCount === null)}
                                    label="Lot Number"
                                    {...inputProps} />
                            </div>
                            <div className="col-md-3 mb-3">
                                <Select id="vesselSizeMg"
                                    label="Vial Size"
                                    {...inputProps}>
                                    <option value=""></option>
                                    <option value="1">1 mg</option>
                                    <option value="5">5 mg</option>
                                    <option value="25">25 mg</option>
                                    <option value="50">50 mg</option>
                                </Select>
                            </div>
                            <div className="col-md-3 mb-3">
                                <Input id="vesselCount"
                                    label="Vial Count"
                                    type="number"
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 mt-3 mb-3">
                                <button className="btn btn-primary w-100" type="submit" disabled={submitDisabled || isSubmitting}>Request CoA</button>
                                <button className="btn btn-secondary ms-3 d-none" type="button" onClick={reset} disabled={!isDirty || isSubmitting}>Reset</button>
                            </div>
                        </div>
                    </form>

                </div>

            </div>

        </>
    );
};
